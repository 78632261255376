<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">

    <h3>Filtri</h3>

    <mat-form-field appearance="outline">
      <mat-label>Codice</mat-label>
      <input matInput formControlName="code" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Tipo risposta</mat-label>
      <input matInput formControlName="answerType" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Nr massimo risposte</mat-label>
      <input matInput formControlName="maxAnswers" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Testo domanda</mat-label>
      <input matInput formControlName="questionTexts" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" placeholder="Search">
    </mat-form-field>

    <br>

    <button mat-raised-button color="primary" (click)="resetFilters()" >
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>
</div>
