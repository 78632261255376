import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { HasRoleModule } from '../../../modules/has-role.module';
import { getUuidV4RegExp } from '../../../utils/forms.handler';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatInput, MatButton, MatLabel, MatIcon, HasRoleModule],
  templateUrl: './persons-filters.component.html',
  styleUrl: './persons-filters.component.scss',
})
export class PersonsFiltersComponent {
  filterForm: FormGroup;
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject the passed data (filters)
    private dialogRef: MatDialogRef<PersonsFiltersComponent>
  ) {
    this.filterForm = this.fb.group({
      email: [data?.email || ''],
      firstName: [data?.firstName || ''],
      lastName: [data?.lastName || ''],
      companyName: [data?.companyName || ''],
      fiscalCode: [data?.fiscalCode || ''],
      personCode: [data?.personCode || ''],
      id: [data?.id || '', Validators.pattern(getUuidV4RegExp())],
      touchpointType: [data?.touchpointType || ''],
      touchpointName: [data?.touchpointName || ''],
    });
  }

  applyFilters() {
    const filterValues = this.filterForm.value;
    this.filtersApplied.emit(filterValues);
    this.dialogRef.close(filterValues);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersApplied.emit({});
  }
}
