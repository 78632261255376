<div *ngIf="isAuthenticated">
  <mat-toolbar class="toolbar-container">
      <!--logo-->
      <span class="logo-container">
        <img ngSrc="assets/logo.png" alt="CMD Logo" class="logo" height="60" width="280" priority="priority">
      </span>
      <span class="spacer"></span>

      <!--title page-->
      <span class="page-title">{{ title }}</span>
      <span class="spacer"></span>
      <!--legal entity-->
      <mat-form-field appearance="fill" class="entity-select">
        <mat-label>Legal Entity</mat-label>
        <mat-select [formControl]="legalEntityControl" (selectionChange)="onSelectionChange($event)" required>
          <mat-option>--</mat-option>
          @for (legalEntity of legalEntities; track $index) {
            <mat-option [value]="legalEntity">{{legalEntity.name}}</mat-option>
          }
        </mat-select>
        @if (legalEntityControl.hasError('required')) {
          <mat-error>Seleziona una legal entity</mat-error>
        }
      </mat-form-field>
      <!--profile-->
      @if (backofficeUserProfile) {
        <button mat-icon-button [matMenuTriggerFor]="userMenu">
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #userMenu="matMenu" class="user-profile-menu">
          <div class="user-profile-content">
            <p><strong>email:</strong> {{ backofficeUserProfile.email }}</p>
            <p><strong>Nome:</strong> {{ backofficeUserProfile.firstName }}</p>
            <p><strong>Cognome:</strong> {{ backofficeUserProfile.lastName }}</p>
            <p><strong>Ruolo:</strong> {{ getRoleLabel(backofficeUserProfile.role) }}</p>
            <p><strong>Legal Entity:</strong> {{ backofficeUserProfile.legalEntityCodes.join(' | ') }}</p>
          </div>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      }

    </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
  <!--sidebar-->
<!--  <mat-sidenav #drawer class="sidenav" mode="side" [opened]="true" fixedInViewport>-->
    <mat-sidenav mode="side" opened class="sidenav">
    <!--menu-->
    <mat-nav-list>
      <a *appHasRole="['viewer','editor','admin','owner']" mat-list-item routerLink="/persone" matTooltip="Persone">
        <mat-icon>person</mat-icon>
        <span>Persone</span>
      </a>
      <a *appHasRole="['viewer','editor','admin','owner']" mat-list-item routerLink="/aziende" matTooltip="Aziende">
        <mat-icon>business</mat-icon>
        <span>Aziende</span>
      </a>
      <a *appHasRole="['viewer','editor','admin','owner']" mat-list-item routerLink="/domande" matTooltip="Domande">
        <mat-icon>chat</mat-icon>
        <span>Domande</span>
      </a>
      <a *appHasRole="['viewer','editor','admin','owner']" mat-list-item routerLink="/edizioni" matTooltip="Edizioni">
        <mat-icon>event</mat-icon>
        <span>Edizioni</span>
      </a>
      <a *appHasRole="['owner']" mat-list-item routerLink="/utenti-backoffice" matTooltip="Utenti Backoffice">
          <mat-icon fontIcon="supervisor_account"></mat-icon>
          <span>Utenti Backoffice</span>
        </a>
    </mat-nav-list>
  </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<router-outlet *ngIf="!isAuthenticated"></router-outlet>