<h1 mat-dialog-title>Nuovo contatto</h1>
<div mat-dialog-content>
  <form [formGroup]="contactForm">

    <mat-form-field>
      <!--todo: aggiungere dropdown per selezionare il tipo di contatto-->
      <mat-label>Tipo</mat-label>
      <input matInput formControlName="contactType" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Valore</mat-label>
      <input matInput formControlName="contactValue" required>
    </mat-form-field>

    <mat-checkbox formControlName="isPreferred">Preferito</mat-checkbox>

  </form>
</div>

<br>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annulla</button>
  <button mat-button (click)="onSubmit()" [disabled]="contactForm.invalid">Salva</button>
</div>