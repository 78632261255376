  <div class="container">
    <div class="header">
      <button mat-raised-button color="primary" (click)="openFilterDialog()">
        <mat-icon fontIcon="search"></mat-icon>Ricerca
      </button>
      <button mat-raised-button color="primary" (click)="refreshList()">
        <mat-icon fontIcon="refresh"></mat-icon>
      </button>
      <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" (click)="navigateToCreate()">Crea domanda</button>
    </div>

    <cdk-virtual-scroll-viewport itemSize="50" class="viewport" (scrollend)="loadMoreData($event)">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
        <!--Row selection and clickable -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
<!--        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></mat-row>-->
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick($event, row)" (mouseup)="onMouseUp($event)"></mat-row>
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Codice </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"> {{element.code}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="questionTexts">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Testo domanda </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"> {{element.questionTexts}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="answerType">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Tipo risposta </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"> {{getAnswerTypeLabel(element.answerType)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="maxAnswers">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nr massimo risposte </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"> {{element.maxAnswers}} </mat-cell>
        </ng-container>

      </mat-table>
    </cdk-virtual-scroll-viewport>

    <div *ngIf="isLoading" class="loader-container">
      <mat-spinner></mat-spinner>
    </div>

  </div>
