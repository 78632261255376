import { Component, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-failed',
  standalone: true,
  imports: [],
  templateUrl: './login-failed.component.html',
  styleUrl: './login-failed.component.scss',
})
export class LoginFailedComponent {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router
  ) {}

  async login() {
    this.authService.initialize().subscribe(() => {
      this.router.navigate(['/']);
    });
  }
}
