import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { HasRoleModule } from '../../../modules/has-role.module';
import { getUuidV4RegExp } from '../../../utils/forms.handler';

@Component({
  selector: 'app-filters-questions',
  standalone: true,
  imports: [FormsModule, MatButton, MatFormField, MatIcon, MatInput, MatLabel, ReactiveFormsModule, HasRoleModule],
  templateUrl: './filters-questions.component.html',
  styleUrl: './filters-questions.component.scss',
})
export class FiltersQuestionsComponent {
  filterForm: FormGroup;
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject the passed data (filters)
    private dialogRef: MatDialogRef<FiltersQuestionsComponent>
  ) {
    this.filterForm = this.fb.group({
      code: [data?.code || ''],
      answerType: [data?.answerType || ''],
      maxAnswers: [data?.maxAnswers || ''],
      questionTexts: [data?.questionTexts || ''],
      id: [data?.id || '', Validators.pattern(getUuidV4RegExp())],
    });
  }

  applyFilters() {
    const filterValues = this.filterForm.value;
    this.filtersApplied.emit(filterValues);
    this.dialogRef.close(filterValues);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersApplied.emit({});
  }
}
