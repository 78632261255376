<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">

    <h3>Filtri</h3>

    <mat-form-field appearance="outline">
      <mat-label>Ragione sociale</mat-label>
      <input matInput formControlName="name" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Partita IVA</mat-label>
      <input matInput formControlName="vatNumber" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Sito web</mat-label>
      <input matInput formControlName="website" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Nazione (ISO 2)</mat-label>
      <input matInput formControlName="countryIso2" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Codice AGEM</mat-label>
      <input matInput formControlName="agemCode" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Nr dipendenti</mat-label>
      <input matInput formControlName="numberEmployees" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Fatturato annuo</mat-label>
      <input matInput formControlName="sales" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Indirizzo</mat-label>
      <input matInput formControlName="address" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Città</mat-label>
      <input matInput formControlName="city" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>Provincia</mat-label>
      <input matInput formControlName="province" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field appearance="outline">
      <mat-label>CAP</mat-label>
      <input matInput formControlName="zipCode" placeholder="Search">
    </mat-form-field>

    <br>

    <button mat-raised-button color="primary" (click)="resetFilters()" >
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>

    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>

</div>
