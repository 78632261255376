export const EVENT_TYPES = [
  { label: 'consent', value: 'consent' },
  { label: 'withdraw-consent', value: 'withdraw-consent' },
  { label: 'proposed-consent', value: 'proposed-consent' },
  { label: 'policy-acknowledgment', value: 'policy-acknowledgment' },
  { label: 'document-acknowledgment', value: 'document-acknowledgment' },
];
export function getEventTypeLabel(value: string): string {
  const item = EVENT_TYPES.find(r => r.value === value);
  return item ? item.label : 'Unknown';
}

export const PROOFS = [
  { label: 'IP', value: 'IP' },
  { label: 'URL', value: 'URL' },
];
export function getProofLabel(value: string): string {
  const item = PROOFS.find(r => r.value === value);
  return item ? item.label : 'Unknown';
}

export const SOURCES = [{ label: 'TOUCHPOINT', value: 'TOUCHPOINT' }];
export function getSourceLabel(value: string): string {
  const item = SOURCES.find(r => r.value === value);
  return item ? item.label : 'Unknown';
}
