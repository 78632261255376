<h1 mat-dialog-title>Nuovo registro privacy</h1>
<mat-dialog-content>
  <form [formGroup]="privacyEventForm" (ngSubmit)="onSubmit()">

    <div formGroupName="action">
      <mat-form-field>
        <mat-label>Lingua</mat-label>
        <input matInput formControlName="language">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Data e ora</mat-label>
        <input matInput formControlName="date" [matDatetimepicker]="picker">
        <mat-datetimepicker-toggle matSuffix [for]="picker"></mat-datetimepicker-toggle>
        <mat-datetimepicker #picker type="datetime"></mat-datetimepicker>
      </mat-form-field>
    </div>

    <div formGroupName="action">
      <div>
        <div formArrayName="proof">
          <h4>Prove di genuinità</h4>
          <div *ngFor="let proof of proofArray.controls; let i = index" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Codice Proof</mat-label>
              <mat-select formControlName="code">
                <mat-option *ngFor="let proofType of privacyProofs" [value]="proofType.value">
                  {{ proofType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valore Proof</mat-label>
              <input matInput formControlName="value">
            </mat-form-field>

            <button mat-icon-button color="warn" type="button" (click)="removeProof(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <br>
          <button mat-raised-button color="primary" type="button" (click)="addProof()">Aggiungi Proof</button>
        </div>
      </div>

      <br>

      <div>
        <div formArrayName="source">
          <h4>Informazioni extra</h4>
          <div *ngFor="let source of sourceArray.controls; let i = index" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Codice Source</mat-label>
              <mat-select formControlName="code">
                <mat-option *ngFor="let sourceType of privacySources" [value]="sourceType.value">
                  {{ sourceType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valore Source</mat-label>
              <input matInput formControlName="value">
            </mat-form-field>

            <button mat-icon-button color="warn" type="button" (click)="removeSource(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <br>
          <button mat-raised-button color="primary" type="button" (click)="addSource()">Aggiungi Source</button>
        </div>
      </div>
    </div>


    <div formArrayName="events">
      <h4>Eventi privacy</h4>
      <div *ngFor="let event of eventsArray.controls; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label>Tipo evento</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let eventType of privacyEventTypes" [value]="eventType.value">
              {{ eventType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Codice evento</mat-label>
          <input matInput formControlName="code">
        </mat-form-field>

        <button mat-icon-button color="warn" type="button" (click)="removeEvent(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <br>
      <button mat-raised-button color="primary" type="button" (click)="addEvent()">Aggiungi evento</button>
    </div>

    <br>

    <button mat-raised-button color="warn" type="button" (click)="onCancel()">Annulla</button>
<!--    <button mat-raised-button color="primary" type="submit" [disabled]="privacyEventForm.invalid">Salva</button>-->
    <button mat-raised-button color="primary" type="submit" [disabled]="privacyEventForm.invalid || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>

  </form>
</mat-dialog-content>
