import { Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ListPersonsComponent } from './components/persons/list/list-persons.component';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { CreateBackofficeUserComponent } from './components/backoffice-users/create/create-backoffice-user.component';
import { EditBackofficeUserComponent } from './components/backoffice-users/edit/edit-backoffice-user.component';
import { ListBackofficeUsersComponent } from './components/backoffice-users/list/list-backoffice-users.component';
import { EditEditionComponent } from './components/editions/edit/edit-edition.component';
import { CreateEditionComponent } from './components/editions/create/create-edition.component';
import { CreatePersonComponent } from './components/persons/create/create-person.component';
import { EditPersonComponent } from './components/persons/edit/edit-person.component';
import { ListCompaniesComponent } from './components/companies/list/list-companies.component';
import { ViewCompanyComponent } from './components/companies/view/view-company.component';
import { ListQuestionsComponent } from './components/questions/list/list-questions.component';
import { CreateQuestionComponent } from './components/questions/create/create-question.component';
import { EditQuestionComponent } from './components/questions/edit/edit-question.component';
import { ListEditionsComponent } from './components/editions/list/list-editions.component';
import { AppComponent } from './app.component';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  // home
  {
    path: '',
    component: AppComponent,
    canActivate: [MsalGuard],
  },
  // login failed
  { path: 'login-failed', component: LoginFailedComponent },
  // persons
  {
    path: 'persone',
    component: ListPersonsComponent,
    data: { title: 'Persone', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  {
    path: 'persone/create',
    component: CreatePersonComponent,
    data: { title: 'Persone', roles: ['editor', 'admin', 'owner'] },
  },
  {
    path: 'persone/edit/:id',
    component: EditPersonComponent,
    data: { title: 'Persone', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  // companies
  {
    path: 'aziende',
    component: ListCompaniesComponent,
    data: { title: 'Aziende', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  {
    path: 'aziende/view/:id',
    component: ViewCompanyComponent,
    data: { title: 'Aziende', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  // questions
  {
    path: 'domande',
    component: ListQuestionsComponent,
    data: { title: 'Domande', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  {
    path: 'domande/create',
    component: CreateQuestionComponent,
    data: { title: 'Domande', roles: ['editor', 'admin', 'owner'] },
  },
  {
    path: 'domande/edit/:id',
    component: EditQuestionComponent,
    data: { title: 'Domande', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  // editions
  {
    path: 'edizioni',
    component: ListEditionsComponent,
    data: { title: 'Edizioni', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  {
    path: 'edizioni/create',
    component: CreateEditionComponent,
    data: { title: 'Edizioni', roles: ['editor', 'admin', 'owner'] },
  },
  {
    path: 'edizioni/edit/:editionCode',
    component: EditEditionComponent,
    data: { title: 'Edizioni', roles: ['viewer', 'editor', 'admin', 'owner'] },
  },
  // backoffice-users
  {
    path: 'utenti-backoffice',
    component: ListBackofficeUsersComponent,
    data: { title: 'Utenti backoffice', roles: ['owner'] },
  },
  {
    path: 'utenti-backoffice/create',
    component: CreateBackofficeUserComponent,
    data: { title: 'Utenti backoffice', roles: ['owner'] },
  },
  {
    path: 'utenti-backoffice/edit/:email',
    component: EditBackofficeUserComponent,
    data: { title: 'Utenti backoffice', roles: ['owner'] },
  },
  // others
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];
