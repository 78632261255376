import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiCmdService } from '../../../../services/api-cmd.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { DataUpdatePersonContactApiRequestDto } from '@packages/api-cmd-type-definitions';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { HasRoleModule } from '../../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-contact-edit-dialog',
  standalone: true,
  imports: [MatLabel, MatDialogTitle, MatDialogContent, ReactiveFormsModule, MatFormField, MatInput, MatCheckbox, MatDialogActions, MatButton, HasRoleModule, MatProgressSpinner, NgIf],
  templateUrl: './contact-edit-dialog.component.html',
  styleUrl: './contact-edit-dialog.component.scss',
})
export class ContactEditDialogComponent {
  contactForm: FormGroup;
  public isLoading = true;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    public dialogRef: MatDialogRef<ContactEditDialogComponent>,
    private confirmationDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { personId: string; contactId: string },
    private snackbar: MatSnackBar
  ) {
    this.contactForm = this.fb.group({
      contactType: ['', Validators.required],
      contactValue: ['', Validators.required],
      isPreferred: [false],
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.apiCmdService.getPersonContact(this.data.personId, this.data.contactId).subscribe({
      next: response => {
        this.contactForm.patchValue(response.data);
        this.isLoading = false;
      },
      error: err => handlerSnackbarApiError(this.snackbar, err,[this.setterIsLoading(false)]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiCmdService.deletePersonContact(this.data.personId, this.data.contactId).subscribe({
          next: () => {
            this.dialogRef.close(true);
          },
          error: err => handlerSnackbarApiError(this.snackbar, err),
        });
      }
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      const updatedContact: DataUpdatePersonContactApiRequestDto['data'] = this.contactForm.value;
      this.apiCmdService.updatePersonContact(this.data.personId, this.data.contactId, { data: updatedContact }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.dialogRef.close(true);
        },
        error: err => handlerSnackbarApiError(this.snackbar, err,[this.setterIsSubmitting(false)]),
      });
    }
  }
}
