<div class="header">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h3>Nuova edizione</h3>
</div>
<mat-card>
  <form [formGroup]="creationForm" (ngSubmit)="submitForm()">
    <mat-form-field>
      <mat-label>Codice macromanifestazione</mat-label>
      <input matInput formControlName="exhibitionGroupCode" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Macromanifestazione</mat-label>
      <input matInput formControlName="exhibitionGroupName">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Codice manifestazione</mat-label>
      <input matInput formControlName="exhibitionCode" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Manifestazione</mat-label>
      <input matInput formControlName="exhibitionName" required>
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Codice edizione</mat-label>
      <input matInput formControlName="editionCode" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Edizione</mat-label>
      <input matInput formControlName="editionName" required>
    </mat-form-field>

    <br>

    <button mat-raised-button color="primary" type="submit" [disabled]="creationForm.invalid || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>
  </form>
</mat-card>
