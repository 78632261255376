import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HasRoleModule } from '../../../modules/has-role.module';
import { getUuidV4RegExp } from '../../../utils/forms.handler';

@Component({
  selector: 'app-companies-filters',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatInput, MatButton, MatLabel, MatIcon, HasRoleModule],
  templateUrl: './companies-filters.component.html',
  styleUrl: './companies-filters.component.scss',
})
export class CompaniesFiltersComponent {
  filterForm: FormGroup;
  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject the passed data (filters)
    private dialogRef: MatDialogRef<CompaniesFiltersComponent>
  ) {
    this.filterForm = this.fb.group({
      name: [data?.name || ''],
      vatNumber: [data?.vatNumber || ''],
      id: [data?.id || '', Validators.pattern(getUuidV4RegExp())],
      agemCode: [data?.agemCode || ''],
      address: [data?.address || ''],
      city: [data?.city || ''],
      province: [data?.province || ''],
      zipCode: [data?.zipCode || ''],
      countryIso2: [data?.countryIso2 || '', [Validators.minLength(2), Validators.maxLength(2)]],
      numberEmployees: [data?.numberEmployees || ''],
      website: [data?.website || ''],
      sales: [data?.sales || ''],
    });
  }

  applyFilters() {
    const filterValues = this.filterForm.value;
    this.filtersApplied.emit(filterValues);
    this.dialogRef.close(filterValues);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersApplied.emit({});
  }
}
