<div class="header">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h3>Nuova persona</h3>
</div>
<mat-card>
  <h3>informazioni principali</h3>

  <form [formGroup]="creationForm" (ngSubmit)="submitForm()">
    <mat-form-field>
      <mat-label>email</mat-label>
      <input matInput formControlName="email" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Fiera ID</mat-label>
      <input matInput formControlName="fieraId">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="firstName" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="lastName" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Codice fiscale</mat-label>
      <input matInput formControlName="fiscalCode">
    </mat-form-field>

    <br>

    <h3>Privacy</h3>

    <div formGroupName="personPrivacy">
      <div formGroupName="action">
        <mat-form-field>
          <mat-label>Lingua</mat-label>
          <input matInput formControlName="language">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Data e ora</mat-label>
          <input matInput formControlName="date" [matDatetimepicker]="picker">
          <mat-datetimepicker-toggle matSuffix [for]="picker"></mat-datetimepicker-toggle>
          <mat-datetimepicker #picker type="datetime"></mat-datetimepicker>
        </mat-form-field>

        <h4>Prove di genuinità</h4>

        <div formArrayName="proof">
          <div *ngFor="let proof of proofs.controls; let i = index" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Codice Proof</mat-label>
              <mat-select formControlName="code">
                <mat-option *ngFor="let proofType of privacyProofs" [value]="proofType.value">
                  {{ proofType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valore Proof</mat-label>
              <input matInput formControlName="value">
            </mat-form-field>

            <button mat-icon-button color="warn" type="button" (click)="removeProof(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div><br>

          <button mat-raised-button color="primary" type="button" (click)="addProof()">Aggiungi prova</button>
        </div>

        <h4>Informazioni extra</h4>

        <div formArrayName="source">
          <div *ngFor="let source of sources.controls; let i = index" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Codice Source</mat-label>
              <mat-select formControlName="code">
                <mat-option *ngFor="let sourceType of privacySources" [value]="sourceType.value">
                  {{ sourceType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valore Source</mat-label>
              <input matInput formControlName="value">
            </mat-form-field>

            <button mat-icon-button color="warn" type="button" (click)="removeSource(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <br>

        <button mat-raised-button color="primary" type="button" (click)="addSource()">Aggiungi fonte</button>
      </div>

      <h4>Eventi privacy</h4>

      <div formArrayName="events">
        <div *ngFor="let event of events.controls; let i = index" [formGroupName]="i">
          <mat-form-field>
            <mat-label>Tipo evento</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let eventType of privacyEventTypes" [value]="eventType.value">
                {{ eventType.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Codice evento</mat-label>
            <input matInput formControlName="code">
          </mat-form-field>
          <button mat-icon-button color="warn" type="button" (click)="removeEvent(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <br>

      <button mat-raised-button color="primary" type="button" (click)="addEvent()">Aggiungi evento</button>
    </div>

    <br>
    <button mat-raised-button color="primary" type="submit" [disabled]="creationForm.invalid || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>
  </form>
</mat-card>
