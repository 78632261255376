import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { IS_ENABLED, ROLES } from '../../../constants/backoffice-user.constants';
import { LegalEntity } from '../../../types/legal-entity.type';
import { SessionStorageService } from '../../../services/session-storage.service';
import { NgForOf } from '@angular/common';
import { HasRoleModule } from '../../../modules/has-role.module';

@Component({
  selector: 'app-filters-backoffice-users',
  standalone: true,
  imports: [FormsModule, MatButton, MatFormField, MatIcon, MatInput, MatLabel, ReactiveFormsModule, MatError, MatOption, MatSelect, MatCheckbox, NgForOf, HasRoleModule],
  templateUrl: './filters-backoffice-users.component.html',
  styleUrl: './filters-backoffice-users.component.scss',
})
export class FiltersBackofficeUsersComponent {
  filterForm: FormGroup;
  @Output() filtersApplied = new EventEmitter<any>();
  protected readonly ROLES = ROLES;
  protected readonly IS_ENABLED = IS_ENABLED;
  legalEntities: LegalEntity[] = this.sessionStorageService.getLegalEntities() || [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject the passed data (filters)
    private dialogRef: MatDialogRef<FiltersBackofficeUsersComponent>,
    private sessionStorageService: SessionStorageService
  ) {
    this.filterForm = this.fb.group({
      email: [data?.email || ''],
      firstName: [data?.firstName || ''],
      lastName: [data?.lastName || ''],
      role: [data?.role || ''],
      isEnabled: [data?.isEnabled !== undefined ? data.isEnabled : ''],
      legalEntityCodes: [data?.legalEntityCodes || ''],
    });
  }

  applyFilters() {
    const filterValues = this.filterForm.value;
    this.filtersApplied.emit(filterValues);
    this.dialogRef.close(filterValues);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersApplied.emit({});
  }
}
