<div class="top-bar">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="page-title">{{backofficeUser?.email}} </span>
</div>
<mat-card class="scrollable-card">
  <mat-card-content>
  <form [formGroup]="backofficeUserForm" (ngSubmit)="onSubmit()">

    <mat-form-field appearance="fill">
      <mat-label>email</mat-label>
      <input matInput [value]="backofficeUser?.email" required readonly disabled>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Ruolo</mat-label>
      <mat-select formControlName="role" required>
        @for (item of ROLES; track $index) {
          <mat-option [value]="item.value">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
      @if (backofficeUserForm.get('role')?.errors) {
        <mat-error>Seleziona un ruolo</mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Stato</mat-label>
      <mat-select formControlName="isEnabled" placeholder="Search">
        @for (item of IS_ENABLED; track $index) {
          <mat-option [value]="item.value">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <br>

    <mat-form-field appearance="fill">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="firstName" required>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="lastName" required>
    </mat-form-field>

    <br>

    <div formArrayName="legalEntityCodes">
      <div *ngFor="let legalEntity of legalEntityCodes.controls; let i = index" [formGroupName]="i">
        <mat-form-field appearance="fill">
          <mat-label>Legal entity</mat-label>
          <mat-select formControlName="legalEntityCode" required>
            <mat-option *ngFor="let entity of legalEntities" [value]="entity.code">
              {{ entity.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button color="warn" type="button" (click)="removeLegalEntityCode(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button mat-raised-button color="primary" type="button" (click)="addLegalEntityCode()">Aggiungi legal entity</button>
    </div>

    <br>

    <button mat-raised-button color="primary" type="submit" [disabled]="backofficeUserForm.invalid || isLoading || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>
  </form>
  </mat-card-content>
</mat-card>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>