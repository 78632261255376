import { Injectable } from '@angular/core';
import { LegalEntity } from '../types/legal-entity.type';
import { BackofficeUserProfile } from '../types/backoffice-user.type';
import { LoggedUser } from '../types/logged-user.type';

const LOGGED_USER_STORAGE_KEY = 'logged-user';
const BACKOFFICE_USER_PROFILE_STORAGE_KEY = 'backoffice-user-profile';
const AUTH_TOKEN_JWT_STORAGE_KEY = 'auth-token-jwt';
const LEGAL_ENTITIES_LIST_STORAGE_KEY = 'legal-entities-list';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  // logged user
  public getLoggedUser(): LoggedUser | null {
    const user = sessionStorage.getItem(LOGGED_USER_STORAGE_KEY);
    return user ? JSON.parse(user) : null;
  }

  public setLoggedUser(user: LoggedUser): void {
    sessionStorage.setItem(LOGGED_USER_STORAGE_KEY, JSON.stringify(user));
  }

  public removeLoggedUser(): void {
    sessionStorage.removeItem(LOGGED_USER_STORAGE_KEY);
  }

  // backoffice user profile
  public getBackofficeUserProfile(): BackofficeUserProfile | null {
    const profile = sessionStorage.getItem(BACKOFFICE_USER_PROFILE_STORAGE_KEY);
    return profile ? JSON.parse(profile) : null;
  }

  public setBackofficeUserProfile(profile: BackofficeUserProfile): void {
    sessionStorage.setItem(BACKOFFICE_USER_PROFILE_STORAGE_KEY, JSON.stringify(profile));
  }

  public removeBackOfficeUserProfile(): void {
    sessionStorage.removeItem(BACKOFFICE_USER_PROFILE_STORAGE_KEY);
  }

  // token jwt
  public getTokenJwt(): string | null {
    const jwt = sessionStorage.getItem(AUTH_TOKEN_JWT_STORAGE_KEY);
    return jwt ? JSON.parse(jwt) : null;
  }

  public setTokenJwt(jwt: string): void {
    sessionStorage.setItem(AUTH_TOKEN_JWT_STORAGE_KEY, JSON.stringify(jwt));
  }

  public removeTokenJwt(): void {
    sessionStorage.removeItem(AUTH_TOKEN_JWT_STORAGE_KEY);
  }

  // legal entities list
  public getLegalEntities(): LegalEntity[] | null {
    const legalEntities = sessionStorage.getItem(LEGAL_ENTITIES_LIST_STORAGE_KEY);
    return legalEntities ? JSON.parse(legalEntities) : null;
  }

  public setLegalEntities(legalEntities: LegalEntity[]): void {
    sessionStorage.setItem(LEGAL_ENTITIES_LIST_STORAGE_KEY, JSON.stringify(legalEntities));
  }

  public removeLegalEntities(): void {
    sessionStorage.removeItem(LEGAL_ENTITIES_LIST_STORAGE_KEY);
  }
}
