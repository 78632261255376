<h1 mat-dialog-title>Modifica profilo aziendale</h1>
<div mat-dialog-content>
  <form [formGroup]="companyProfileForm">
    <div formGroupName="company">
      <mat-form-field>
        <mat-label>Ragione sociale</mat-label>
        <input matInput formControlName="name" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Partita IVA</mat-label>
        <input matInput formControlName="vatNumber">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Testata stampa/brand</mat-label>
        <input matInput formControlName="brand">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ruolo aziendale</mat-label>
        <input matInput formControlName="businessRole" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sito web professionale</mat-label>
        <input matInput formControlName="website">
      </mat-form-field>
    </div>
    <div formGroupName="company">
      <mat-form-field>
        <mat-label>Sito Web</mat-label>
        <input matInput formControlName="website">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefono</mat-label>
        <input matInput formControlName="phone">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nazione</mat-label>
        <input matInput formControlName="countryIso2">
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Indirizzo</mat-label>
        <input matInput formControlName="address">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Città</mat-label>
        <input matInput formControlName="city">
      </mat-form-field>
      <mat-form-field>
        <mat-label>CAP</mat-label>
        <input matInput formControlName="zipCode">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Provincia</mat-label>
        <input matInput formControlName="province">
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Numero Dipendenti</mat-label>
        <input matInput formControlName="numberEmployees">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Fatturato annuo</mat-label>
        <input matInput formControlName="sales">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Codice AGEM</mat-label>
        <input matInput formControlName="agemCode">
      </mat-form-field>
    </div>
  </form>

  <br>

  <div>
    <h3>Contatti</h3>
    <div>
      <button *appHasRole="['editor','admin','owner']" mat-button (click)="openCreateContactDialog()">Aggiungi contatto</button>
    </div>
    <br>
    <div>
      <table mat-table [dataSource]="personContacts" class="mat-elevation-z8">
        <ng-container matColumnDef="contactType">
          <th mat-header-cell *matHeaderCellDef> Tipo </th>
          <td mat-cell *matCellDef="let contact"> {{contact.contactType}} </td>
        </ng-container>

        <ng-container matColumnDef="contactValue">
          <th mat-header-cell *matHeaderCellDef> Valore </th>
          <td mat-cell *matCellDef="let contact"> {{contact.contactValue}} </td>
        </ng-container>

        <ng-container matColumnDef="isPreferred">
          <th mat-header-cell *matHeaderCellDef> Preferito </th>
          <td mat-cell *matCellDef="let contact">
            <mat-checkbox [checked]="contact.isPreferred" disabled></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['contactType', 'contactValue', 'isPreferred']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['contactType', 'contactValue', 'isPreferred'];" (click)="openEditContactDialog(row.id)"></tr>
      </table>
    </div>
  </div>
</div>

<br>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annulla</button>
  <button *appHasRole="['admin','owner']" mat-button (click)="onDelete()">Elimina</button>
<!--  <button *appHasRole="['editor','admin','owner']" mat-button (click)="onSubmit()" [disabled]="companyProfileForm.invalid || isLoading">Salva</button>-->
  <button *appHasRole="['editor','admin','owner']" mat-button (click)="onSubmit()" [disabled]="companyProfileForm.invalid || isLoading || isSubmitting">
    <ng-container *ngIf="!isSubmitting">Salva</ng-container>
    <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
  </button>
</div>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>