<div *ngIf="company; else loading">
  <div class="top-bar">
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="company-name">{{ company.name }}</span>
  </div>

  <mat-card class="scrollable-card">
    <mat-card-content>
      <form>
        <div>
          <mat-form-field appearance="fill">
            <mat-label matListIcon>Ragione sociale</mat-label>
            <input matInput [value]="company.name" required readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Partita IVA</mat-label>
            <input matInput [value]="company.vatNumber" readonly>
          </mat-form-field>

          <br>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Sito web aziendale</mat-label>
            <input matInput [value]="company.website" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Nr telefono aziendale</mat-label>
            <input matInput [value]="company.phone" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Nazione</mat-label>
            <input matInput [value]="company.countryIso2" readonly>
          </mat-form-field>

          <br>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Indirizzo</mat-label>
            <input matInput [value]="company.address" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Città</mat-label>
            <input matInput [value]="company.city" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>CAP</mat-label>
            <input matInput [value]="company.zipCode" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Provincia</mat-label>
            <input matInput [value]="company.province" readonly>
          </mat-form-field>

          <br>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Nr dipendenti</mat-label>
            <input matInput [value]="company.numberEmployees" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Fatturato annuo</mat-label>
            <input matInput [value]="company.sales" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label matListIcon>Codice AGEM</mat-label>
            <input matInput [value]="company.agemCode" readonly>
          </mat-form-field>
        </div>

        <br>

        <h3>Persona collegata</h3>

        <button mat-raised-button color="primary" (click)="onClickPersonLink(company.person.id)">Vai alla persona collegata</button>

        <br>

        <div>
            <mat-form-field appearance="fill">
              <mat-label matListIcon>email</mat-label>
              <input matInput [value]="company.person.email" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label matListIcon>Fiera ID</mat-label>
              <input matInput [value]="company.person.fieraId" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label matListIcon>Codice persona</mat-label>
              <input matInput [value]="company.person.personCode" readonly>
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill">
              <mat-label matListIcon>Nome</mat-label>
              <input matInput [value]="company.person.firstName" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label matListIcon>Cognome</mat-label>
              <input matInput [value]="company.person.lastName" readonly>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label matListIcon>Codice fiscale</mat-label>
              <input matInput [value]="company.person.fiscalCode" readonly>
            </mat-form-field>
        </div>

      </form>

    </mat-card-content>
  </mat-card>
</div>
<ng-template #loading>
  <div class="loading-container">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p>Caricamento dettagli azienda...</p>
  </div>
</ng-template>

