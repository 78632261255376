<h1 mat-dialog-title>Modifica contatto</h1>
<div mat-dialog-content>
  <form [formGroup]="contactForm">

    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <input matInput formControlName="contactType" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Valore</mat-label>
      <input matInput formControlName="contactValue" required>
    </mat-form-field>

    <mat-checkbox formControlName="isPreferred">Preferito</mat-checkbox>

  </form>
</div>
<div mat-dialog-actions>

  <br>

  <button mat-button (click)="onCancel()">Annulla</button>
  <button *appHasRole="['admin','owner']" mat-button (click)="onDelete()">Elimina</button>
<!--  <button *appHasRole="['editor','admin','owner']" mat-button (click)="onSubmit()" [disabled]="contactForm.invalid || isLoading">Salva</button>-->
  <button *appHasRole="['editor','admin','owner']" mat-button (click)="onSubmit()" [disabled]="contactForm.invalid || isLoading || isSubmitting">
    <ng-container *ngIf="!isSubmitting">Salva</ng-container>
    <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
  </button>
</div>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>