import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

export function handlerSnackbarApiError(
  snackbar: MatSnackBar,
  err: any,
  lambdas: Function[] = [],
  defaultMessage?: string,
  position: { horizontal: MatSnackBarHorizontalPosition; vertical: MatSnackBarVerticalPosition } = { horizontal: 'center', vertical: 'bottom' },
  timeout: number = 5e3
): void {
  console.error(err);

  lambdas.forEach(lambda => {
    try {
      lambda();
    } catch (e) {
      console.error(e);
    }
  });

  let showingMessage = '';
  if (defaultMessage) {
    showingMessage = defaultMessage || 'Error from API';
  } else {
    const httpError = err.error;
    if (httpError) {
      const { error, message, statusCode } = httpError;
      if (statusCode === 400) {
        const [firstError] = message;
        if (firstError && firstError.message) showingMessage = error + '. ' + firstError.message;
      } else if (statusCode === 401) {
        showingMessage = 'Unauthorized';
      } else if (statusCode === 403) {
        showingMessage = 'Forbidden';
      } else if (statusCode === 500) {
        showingMessage = 'Internal server error';
      } else {
        showingMessage = error + '. ' + (message || '');
      }
    } else showingMessage = err.message || showingMessage;
  }

  snackbar.openFromComponent(SnackbarComponent, {
    data: { message: showingMessage, duration: timeout },
    duration: timeout,
    horizontalPosition: position.horizontal,
    verticalPosition: position.vertical,
  });
}
