<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">

    <h3>Filtri</h3>

    <mat-form-field>
      <mat-label>Codice macromanifestazione</mat-label>
      <input matInput formControlName="exhibitionGroupCode" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Macromanifestazione</mat-label>
      <input matInput formControlName="exhibitionGroupName">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Codice manifestazione</mat-label>
      <input matInput formControlName="exhibitionCode" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Manifestazione</mat-label>
      <input matInput formControlName="exhibitionName" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Codice edizione</mat-label>
      <input matInput formControlName="editionCode" placeholder="Search">
    </mat-form-field>

    <br>

    <mat-form-field>
      <mat-label>Edizione</mat-label>
      <input matInput formControlName="editionName" placeholder="Search">
    </mat-form-field>

    <br>

    <button mat-raised-button color="primary" (click)="resetFilters()" >
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>
</div>
