import { Component, Inject } from '@angular/core';
import { DataCreatePersonCompanyProfileApiRequestDto } from '@packages/api-cmd-type-definitions';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ApiCmdService } from '../../../../services/api-cmd.service';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef } from '@angular/material/table';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { NgForOf } from '@angular/common';
import { HasRoleModule } from '../../../../modules/has-role.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../../utils/snackbar.handler';

@Component({
  selector: 'app-company-profile-create-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatCheckbox,
    MatIconButton,
    MatIcon,
    NgForOf,
    HasRoleModule,
  ],
  templateUrl: './company-profile-create-dialog.component.html',
  styleUrl: './company-profile-create-dialog.component.scss',
})
export class CompanyProfileCreateDialogComponent {
  companyProfileForm: FormGroup;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    public dialogRef: MatDialogRef<CompanyProfileCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personId: string },
    private snackbar: MatSnackBar
  ) {
    this.companyProfileForm = this.fb.group({
      businessRole: ['', Validators.required],
      brand: [''],
      website: [''],
      company: this.fb.group({
        name: ['', Validators.required],
        phone: [''],
        vatNumber: [''],
        agemCode: [''],
        address: [''],
        city: [''],
        province: [''],
        zipCode: [''],
        countryIso2: ['', [Validators.minLength(2), Validators.maxLength(2)]],
        numberEmployees: [''],
        website: [''],
        sales: [''],
      }),
      personContacts: this.fb.array([]),
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  get personContacts(): FormArray {
    return this.companyProfileForm.get('personContacts') as FormArray;
  }

  addContact(): void {
    const contactForm = this.fb.group({
      contactType: ['', Validators.required],
      contactValue: ['', Validators.required],
      isPreferred: [false],
    });
    this.personContacts.push(contactForm);
  }

  removeContact(index: number): void {
    this.personContacts.removeAt(index);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.companyProfileForm.valid) {
      this.isSubmitting = true;
      const createDto: DataCreatePersonCompanyProfileApiRequestDto['data'] = this.companyProfileForm.value;
      Object.keys(createDto).forEach(key => {
        // @ts-expect-error TS7053
        if (!createDto[key]) createDto[key] = undefined;
      });
      if (createDto.company)
        Object.keys(createDto.company).forEach(key => {
          // @ts-expect-error TS7053
          if (!createDto.company[key]) createDto.company[key] = undefined;
        });
      this.apiCmdService.createPersonCompanyProfile(this.data.personId, { data: createDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.dialogRef.close(true);
        },
        error: err => handlerSnackbarApiError(this.snackbar, err,[this.setterIsSubmitting(false)]),
      });
    }
  }
}
