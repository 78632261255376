import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { GetCompanyResponse } from '@packages/api-cmd-type-definitions/lib/companies/get-company.dto';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { HasRoleModule } from '../../../modules/has-role.module';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-view-company',
  standalone: true,
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.scss'],
  imports: [MatIcon, MatButton, MatLabel, MatCard, MatCardContent, MatFormField, MatInput, MatProgressSpinner, NgIf, MatIconButton, HasRoleModule],
})
export class ViewCompanyComponent implements OnInit {
  company: GetCompanyResponse | undefined;
  private router = inject(Router);
  public isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private apiCmdService: ApiCmdService,
    private snackbar: MatSnackBar
  ) {}

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  ngOnInit(): void {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (companyId) {
      this.isLoading = true;
      this.apiCmdService.getCompany(companyId).subscribe({
        next: response => {
          this.company = response.data;
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  onClickPersonLink(personId: string): void {
    this.router.navigate(['/persone/edit', personId]);
  }

  navigateBack(): void {
    this.router.navigate(['/aziende']);
  }
}
