import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiCmdService } from '../../../../services/api-cmd.service';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { DataCreatePrivacyEventsApiRequestDto } from '@packages/api-cmd-type-definitions';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgForOf } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { PrivacyEventsRequest } from '@packages/api-cmd-type-definitions/lib/privacy/events.dto';
import { MatIcon } from '@angular/material/icon';
import { HasRoleModule } from '../../../../modules/has-role.module';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { EVENT_TYPES, PROOFS, SOURCES } from '../../../../constants/privacy.constants';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-privacy-event-dialog',
  standalone: true,
  imports: [
    MatLabel,
    MatError,
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgIf,
    NgForOf,
    MatButton,
    MatIcon,
    MatIconButton,
    MatSuffix,
    HasRoleModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
  ],
  templateUrl: './privacy-event-dialog.component.html',
  styleUrl: './privacy-event-dialog.component.scss',
})
export class PrivacyEventDialogComponent implements OnInit {
  privacyEventForm: FormGroup;
  privacyEventTypes = EVENT_TYPES;
  privacyProofs = PROOFS;
  privacySources = SOURCES;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    public dialogRef: MatDialogRef<PrivacyEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personId: string },
    private snackbar: MatSnackBar
  ) {
    this.privacyEventForm = this.fb.group({
      action: this.fb.group({
        language: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
        date: ['', Validators.required],
        proof: this.fb.array([
          this.fb.group({
            code: ['', Validators.required],
            value: ['', Validators.required],
          }),
        ]),
        source: this.fb.array([
          this.fb.group({
            code: ['', Validators.required],
            value: ['', Validators.required],
          }),
        ]),
      }),
      events: this.fb.array([this.createEventGroup()]),
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {}

  createEventGroup(): FormGroup {
    return this.fb.group({
      type: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  get proofArray(): FormArray {
    return this.privacyEventForm.get('action.proof') as FormArray;
  }

  get sourceArray(): FormArray {
    return this.privacyEventForm.get('action.source') as FormArray;
  }

  get eventsArray(): FormArray {
    return this.privacyEventForm.get('events') as FormArray;
  }

  addProof(): void {
    this.proofArray.push(
      this.fb.group({
        code: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  removeProof(index: number): void {
    this.proofArray.removeAt(index);
  }

  addSource(): void {
    this.sourceArray.push(
      this.fb.group({
        code: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  removeSource(index: number): void {
    this.sourceArray.removeAt(index);
  }

  addEvent(): void {
    this.eventsArray.push(this.createEventGroup());
  }

  removeEvent(index: number): void {
    this.eventsArray.removeAt(index);
  }

  onSubmit(): void {
    if (this.privacyEventForm.valid) {
      this.isSubmitting = true;
      const dto: DataCreatePrivacyEventsApiRequestDto['data'] = this.privacyEventForm.value;
      if (dto.action.proof?.length === 0) {
        delete dto.action.proof;
      }
      if (dto.action.source?.length === 0) {
        delete dto.action.source;
      }
      this.apiCmdService.createEventPrivacyPerson(this.data.personId, { data: dto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.dialogRef.close('success');
        },
        error: err => handlerSnackbarApiError(this.snackbar, err,[this.setterIsSubmitting(false)]),
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
