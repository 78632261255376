<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">

    <h3>Filtri</h3>

    <!--email-->
    <mat-form-field appearance="outline">
      <mat-label>email</mat-label>
      <input matInput formControlName="email" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Nome-->
    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="firstName" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Cognome-->
    <mat-form-field appearance="outline">
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="lastName" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Ragione sociale-->
    <mat-form-field appearance="outline">
      <mat-label>Ragione sociale</mat-label>
      <input matInput formControlName="companyName" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Codice fiscale-->
    <mat-form-field appearance="outline">
      <mat-label>Codice fiscale</mat-label>
      <input matInput formControlName="fiscalCode" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Codice persona-->
    <mat-form-field appearance="outline">
      <mat-label>Codice persona</mat-label>
      <input matInput formControlName="personCode" placeholder="Search">
    </mat-form-field>

    <br>

    <!--ID-->
    <mat-form-field appearance="outline">
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Tipo touchpoint-->
    <mat-form-field appearance="outline">
      <mat-label>Tipo touchpoint</mat-label>
      <input matInput formControlName="touchpointType" placeholder="Search">
    </mat-form-field>

    <br>

    <!--Nome touchpoint-->
    <mat-form-field appearance="outline">
      <mat-label>Nome touchpoint</mat-label>
      <input matInput formControlName="touchpointName" placeholder="Search">
    </mat-form-field>

    <br>

    <button mat-raised-button color="primary" (click)="resetFilters()" >
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>

</div>
